<template>
    <section class="feature-1">
        <div class="position-relative container py-5 overflow-hidden">
            <div class="position-absolute pattern">
                <svg id="pattern-3"
                     class="h-100"
                     v-bind:class="{ 'done': isPatternVivusDone }"
                     version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 149.0121613 188.5822906" xml:space="preserve">
                    <path fill="transparent" stroke="#D8E4F0" d="M97.9710388,10.1486006
                        c7.401062-3.4500651,15.1672974-6.126678,23.1032104-8.1458206c1.9857178-0.4663008,3.9649658-1.0339354,6.1036987-1.1578292
                        c1.059021-0.0532074,2.163147,0.000412,3.2357178,0.3534851c1.0737915,0.3328247,2.0791626,1.0392226,2.7468872,1.9869612
                        c0.6721191,0.9509966,0.9926147,2.0575945,1.1584473,3.1177981c0.1641846,1.0679169,0.196228,2.120254,0.2059326,3.1416545
                        c0.0585938,2.0586929-0.0007935,4.1164627-0.0913696,6.1710587c-0.3883667,8.2292719-1.9447632,16.310112-3.4212036,24.3279953
                        c-1.5230103,8.0138855-2.8446045,16.0327911-3.7764893,24.1188393c-1.0043335,8.0720444-1.7061768,16.1830368-2.09198,24.3108139
                        c-0.4073486,8.1284637-0.3887329,16.270462-0.2279053,24.4086151c0.0823975,4.069931,0.2841797,8.1369019,0.5285645,12.2017365
                        l0.4412842,6.0928268c0.1689453,2.0141449,0.3860474,4.0242462,1.1677856,5.8019714
                        c0.7415771,1.7953796,2.2440186,3.1532745,4.0445557,3.9830475c1.7927856,0.8550415,3.7869873,1.2958374,5.777832,1.6917267
                        c3.9885864,0.7776947,8.0550537,1.1953278,12.1361694,1.2470703c-4.0784912,0.1646729-8.1830444-0.0716858-12.2288818-0.725235
                        c-2.0147095-0.3259583-4.0629272-0.6918793-5.9980469-1.5262299c-0.9654541-0.4120941-1.8890991-0.9650421-2.6989746-1.6762238
                        c-0.8034058-0.720047-1.4592896-1.6140442-1.9229736-2.5835266c-0.9263306-1.9559937-1.1750488-4.078476-1.3980713-6.1061249
                        l-0.5804443-6.1050797c-0.3370972-4.0745392-0.6314697-8.1548004-0.8062744-12.2415924
                        c-0.7521362-16.3480072-0.020752-32.7558746,1.8743286-49.0154839c0.99646-8.1226501,2.3911133-16.208313,3.9746094-24.2159576
                        c1.5657349-8.0054016,3.0585938-16.0056076,3.6775513-24.0825882c0.1340332-2.0186615,0.2379761-4.0409927,0.2252808-6.0626755
                        c0.0153198-2.0036998-0.0085449-4.0591655-1.0043945-5.5589447c-0.4962158-0.7296219-1.2262573-1.2827835-2.0939941-1.5757675
                        c-0.8625488-0.3085938-1.8347168-0.3886946-2.8063354-0.3599318c-1.9499512,0.0586091-3.9376221,0.5825347-5.9297485,0.9867629
                        C113.3364563,4.6385608,105.5472717,7.1136355,97.9710388,10.1486006z"></path>
                    <path fill="transparent" stroke="#D8E4F0" d="M70.878418,5.1094623
                        c0.8805237,1.573616,1.699646,3.1977458,2.2497559,4.9455638c0.5641479,1.7296295,0.8722534,3.6287994,0.4806213,5.5301514
                        c-0.4087219,1.9013529-1.7231445,3.5788889-3.3400879,4.6057901c-1.6088257,1.0585098-3.3821411,1.6430206-5.0326233,2.3052673
                        c-1.6639748,0.6492615-3.1907692,1.3801346-4.5348854,2.4384689c-1.3464966,1.0392914-2.5137634,2.3036346-3.4946899,3.7097168
                        c-1.9372559,2.8382874-3.1960449,6.1574097-3.9186096,9.5868683c-0.7953491,3.428299-1.0395203,6.957962-1.0801392,10.5267944
                        c-0.0700684,3.5628204,0.1142883,7.1321716,0.4380493,10.6944733c0.680481,7.1237755,1.986145,14.2068443,3.5383911,21.2546806
                        c-2.3003845-6.8375244-4.135498-13.87117-5.0889893-21.0775719c-0.5149231-3.5966339-0.7402954-7.2367249-0.781189-10.8813477
                        c-0.0169983-3.6310883,0.2184753-7.3403625,1.0223389-10.9491882c0.8014221-3.5989838,2.1392517-7.1594543,4.3294678-10.2465668
                        c1.1194458-1.5212097,2.4563293-2.8991852,3.9936523-4.0213013c1.5177917-1.1511688,3.3227234-1.9168854,5.0373573-2.4932327
                        c1.71875-0.5920258,3.4143982-1.1104355,4.8817139-1.9541779c1.4692383-0.8221664,2.6081543-2.1239548,3.0869751-3.7441797
                        c0.4790344-1.6153259,0.3669128-3.4240417-0.0455627-5.1446075C72.2120056,8.4645948,71.5665894,6.7762866,70.878418,5.1094623z"></path>
                    <path fill="transparent" stroke="#D8E4F0" d="M81.3553162,40.7342949
                        c1.9314575,2.3121338,3.4212036,4.9883118,4.6104736,7.7855835c1.1484375,2.8182373,1.9334106,5.7913361,2.3678589,8.8132324
                        c0.8895874,6.0519562,0.4490356,12.2528038-0.9359741,18.1918144c-1.4248047,5.930191-3.7102051,11.5964661-6.4588013,16.9666138
                        c-2.7264709,5.3899689-5.9230042,10.4891968-9.2259827,15.5075836c2.6294556-5.4057159,5.3616028-10.7481384,7.8183289-16.1815643
                        c2.4300537-5.4408417,4.5748291-11.0007477,5.9216919-16.7544556c1.3740234-5.7264404,1.8831177-11.6733513,1.3374023-17.5424919
                        C86.2156677,51.6678276,84.540863,45.8490715,81.3553162,40.7342949z"></path>
                    <path fill="transparent" stroke="#D8E4F0" d="M59.6160851,60.7085686
                        c0.2911987,6.4154778,0.6207581,12.8294182,0.4911499,19.2794762c-0.0995789,3.2220306-0.3295288,6.4535675-0.8796692,9.6621704
                        c-0.5440369,3.2096405-1.5299377,6.3825378-2.9700012,9.3351746c-2.8609924,5.8975525-7.9156494,10.9733887-14.3716125,13.0563965
                        c-1.6404419,0.5135345-3.2877502,0.7155609-4.9011536,0.9035034c-1.6237793,0.1770782-3.2413292,0.2787476-4.843441,0.3773346
                        c-3.2009602,0.197876-6.3674641,0.39151-9.3829365,1.109314c-6.019104,1.3782196-11.5304861,5.0097046-15.2018127,10.0052643
                        c-0.9057312,1.2571411-1.7025757,2.5882263-2.3817444,3.9788437c-0.6498413,1.4046783-1.1669006,2.8633118-1.554718,4.3628235
                        c-0.3562012,1.5076752-0.5714724,3.0460205-0.6716311,4.5954742c-0.0737,1.5510712-0.0183411,3.108429,0.1671145,4.6551971
                        c0.2185669,1.541748,0.564636,3.0648346,1.0421143,4.5505524c0.5091553,1.4742584,1.1471558,2.9023132,1.9131165,4.2639313
                        c0.7975769,1.3422394,1.7208557,2.6075287,2.7636418,3.7732544c1.0715637,1.1404724,2.2569885,2.1721497,3.5265198,3.099884
                        c5.0940237,3.684845,11.8005972,5.262207,18.0957947,3.8893127c-6.2068787,1.8218384-13.1730042,0.4780273-18.6496582-3.0802917
                        c-1.3603516-0.9159241-2.6271973-1.969574-3.7907715-3.1357422c-1.1343999-1.1958618-2.1470647-2.5029907-3.029633-3.8979797
                        c-0.8505554-1.4137115-1.568634-2.9042664-2.1513672-4.450119c-0.5509033-1.5567932-0.9642334-3.1602173-1.2421265-4.7911224
                        c-1.1152344-6.5276184,0.5523071-13.5181885,4.491333-18.8847885c3.8900762-5.3885193,9.7250671-9.3323517,16.2199402-10.8799286
                        c3.2497559-0.7389069,6.5282898-0.8980255,9.7280293-1.0679932c1.6024475-0.0833893,3.1907005-0.1688843,4.7599449-0.3252716
                        c1.5716553-0.1668549,3.1315002-0.3510437,4.5621948-0.7888184c2.9127197-0.8919525,5.6174622-2.5449829,7.8972168-4.6312714
                        c1.1344299-1.0524902,2.195343-2.1941528,3.1199646-3.4459076c0.9624023-1.2237244,1.7714844-2.5643311,2.5208435-3.9364624
                        c1.4529724-2.7822418,2.480072-5.7680817,3.0984192-8.8724365c0.6285706-3.1053772,0.934082-6.2884521,1.1424561-9.4756317
                        C59.590847,73.5608902,59.4573021,67.1295853,59.6160851,60.7085686z"></path>
                    <path fill="transparent" stroke="#D8E4F0" d="M33.1707764,152.6430054
                        c-0.0420532,6.0556335,1.6757774,12.0309143,4.7915001,17.1134949c3.0740967,5.0988464,7.4919739,9.372467,12.7543335,12.0449829
                        c5.26651,2.7742615,10.9996643,4.782959,16.8731117,4.8520813c5.8596497,0.1698303,11.6236267-1.8670959,16.3153381-5.37854
                        c4.75177-3.4720154,8.5222778-8.2044983,11.611084-13.2802429c3.0928345-5.0984802,5.4862061-10.6295166,7.3644409-16.3158875
                        c0.9296875-2.8478394,1.7038574-5.7491913,2.2921753-8.6815643c0.6167603-2.9139709,0.7802734-5.9342651,1.1289673-8.9441528
                        c0.6776733-6.0097046,1.2860718-12.0265732,2.0245361-18.0336533c-0.2844238,6.0458374-0.6989746,12.0819321-1.0441895,18.125145
                        c-0.2312622,3.0068054-0.2897949,6.0517578-0.8187256,9.0859985c-0.4953613,3.0186615-1.2314453,5.9856567-2.0986328,8.9117889
                        c-1.7415771,5.8499756-4.1411743,11.515625-7.2324219,16.8179016c-3.1211548,5.2651978-7.0272217,10.20047-12.0458984,13.9222717
                        c-4.9799805,3.7262268-11.2631531,5.9021606-17.536377,5.6847839c-6.2788124-0.1824646-12.2706642-2.3709717-17.5921669-5.3456421
                        C38.9722862,177.322113,32.2006531,164.7596436,33.1707764,152.6430054z"></path>
                    <path fill="transparent" stroke="#D8E4F0" d="M31.9995098,112.9556961
                        c12.2742901,0.5986328,24.4483013,3.4294434,35.9529419,7.8618774c5.7450256,2.2473907,11.3403015,4.8850861,16.6789246,7.9658585
                        c5.3513794,3.0550995,10.4364624,6.5649109,15.1694946,10.4759216c-5.1890259-3.2808075-10.4976196-6.3107452-15.9144287-9.1044769
                        c-5.4296875-2.7682114-10.977417-5.2890091-16.6569519-7.4738083
                        C55.8861351,118.2493973,44.0792809,115.1196976,31.9995098,112.9556961z"></path>
                </svg>
            </div>

            <h1 class="position-relative text-center font-weight-bold mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                2大定點修形優勢
            </h1>

            <div class="position-relative row mb-5">
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <h3>
                        <span class="mr-1">
                            定點脂肪凋亡 (40%)
                        </span>
                        <a class="font-weight-bolder text-decoration-none"
                           style="font-size: 110%; "
                           href="#mono-sculpting-disclaimer">
                            *
                        </a>
                    </h3>
                </div>
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <img class="icon"
                         alt="定點脂肪凋亡 (40%)"
                         src="../../../assets/campaign/mono-sculpting/skin_icon_1.png">
                </div>
            </div>

            <div class="position-relative row">
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <h3>
                        加強雕塑修形
                    </h3>
                </div>
                <div class="col-md-6 d-flex justify-content-center align-items-center">
                    <img class="icon"
                         alt="加強雕塑修形"
                         src="../../../assets/campaign/mono-sculpting/skin_icon_2.png">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vivus from "vivus";

    export default {
        name: "MonoSculptingFeature1",
        data () {
            return {
                patternVivus: null,
                isPatternVivusDone: false,
            };
        },
        mounted () {
            this.patternVivus = new Vivus(
                "pattern-3",
                {
                    type: "delayed",
                    start: "inViewport",
                    duration: 150
                },
                () => {
                    this.isPatternVivusDone = true;
                }
            );
        }
    }
</script>

<style lang="scss" scoped>
    @import "./variables.scss";

    .feature-1 {
        background-color: $background-color;
        color: $text-color;

        .icon {
            width: 100%;
            height: auto;
        }

        .pattern {
            top: 1rem;
            left: 0;
            bottom: 1rem;

            svg {
                &#pattern-3 {
                    path {
                        transition: fill 250ms;
                    }

                    &.done {
                        path {
                            fill: $background-color-alt;
                        }
                    }
                }
            }
        }
    }
</style>
